.wrapper 
  @apply flex gap-1.5 items-center border px-3 py-[1px] rounded-full

  & span 
    @apply text-sm

.contained
  &.dark-700 
    @apply bg-dark-700 text-white border-dark-700
  &.blue-700 
    @apply bg-blue-200 text-white border-dark-200
  &.white
    @apply border-dark-100 bg-white text-dark-600
  &.dark-400
    @apply border-dark-400 bg-dark-400 text-white

.outlined
  &.info
    @apply border-[#2196F3] bg-white
  &.success
    @apply border-[#4CAF50] bg-white

